import React from 'react';
import { Added, Improved, Link, PageWithSubNav, Paragraph, PlatformHeader } from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="March 2023" subnav="release-notes">
      <div id="March2023" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          We sure are <strong>lucky</strong> to have you check out our March release notes! Not a fan of leprechauns?
          Well, hopefully you find something worth its weight in gold in this month's updates.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.29.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Improved>
                Implementation of <Link href="/components/icons/overview/code">icons</Link> for better accessibility.
              </Improved>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.7.0"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Added>
                Export the <code>Icon</code> component to use with custom SVGs. This already exists in the web library.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
